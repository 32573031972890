import React from "react";
import Whiteboard from "./Whiteboard";

const PostWhiteboard = () => {
  return (
    <div>
      <Whiteboard whiteboardID={null} />
    </div>
  );
};

export default PostWhiteboard;
