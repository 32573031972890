import React, { useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./LandingPage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import FAQSlider from "./FAQSlider";

const LandingPage = () => {
  const aboutSynapseRef = useRef(null);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <FontAwesomeIcon
          key={i}
          icon={faStar}
          className={i < rating ? styles.filledStar : styles.emptyStar}
        />
      );
    }
    return stars;
  };

  // Define your FAQs
  const faqs = [
    {
      question: "Why use Synapse?",
      answer:
        "Synapse isn't just any normal revision tool - it was built with psychological models of memory in mind to ensure that everything you are doing is beneficial. <br><br>We believe that this makes Synapse the best revision tool out there for learning content.",
    },
    {
      question: "How do I sign up?",
      answer:
        "You can sign up by clicking the 'Sign Up' button on the landing page and filling out the registration form.",
    },
    {
      question: "Is Synapse free to use?",
      answer:
        "Yes, Synapse is fully free - designed for students by students. Unlike other sites, we will never lock content behind a paywall and always ensure you have everything you need with regular updates.",
    },
    {
      question: "What is active recall?",
      answer:
        "Active recall is a study technique that involves actively stimulating your memory during the learning process. This method is more effective than passive review because it helps reinforce the memory pathways.",
    },
    {
      question: "How does Synapse utilize active recall?",
      answer:
        "Synapse incorporates active recall techniques in its study modes, such as flashcards and quizzes, to help students actively engage with the material and improve retention.",
    },
    {
      question: "What psychological models does Synapse use?",
      answer:
        "Synapse uses several psychological models of memory, including spaced repetition and the testing effect, to ensure that the study methods are scientifically proven to be effective.",
    },
    {
      question: "How does spaced repetition work?",
      answer:
        "Spaced repetition involves reviewing material at increasing intervals over time. This technique helps transfer information from short-term to long-term memory, making it easier to retain information for longer periods.",
    },
    {
      question: "Can Synapse help with exam preparation?",
      answer:
        "Yes, Synapse is designed to help students prepare for exams by providing tools like exam timers, practice tests, and study schedules that incorporate effective study techniques.",
    },
    {
      question: "What types of study materials can I create with Synapse?",
      answer:
        "With Synapse, you can create flashcards, notes, mindmaps, quizzes, and more. These tools are designed to cater to different learning styles and preferences.",
    },
  ];

  const handleLearnMoreClick = () => {
    aboutSynapseRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.mainContent}>
      <img src="heroimage.png" className={styles.heroImage} />
      <div className={styles.heroSection}>
        <h1 className={styles.mainText}>Synapse</h1>
        <h3 className={styles.reviseText}>
          The revision platform with no distractions.
        </h3>
        <button onClick={handleLearnMoreClick} className={styles.signUpButton}>
          Learn more
        </button>
      </div>
      <div className={styles.starsSection}>
        <div className={styles.starCard}>
          <div>{renderStars(5)}</div>
          <p>
            "Excellent product, would recommend to all people studying at both
            GCSE and A-Level"
          </p>
          <p>- Arnav Maniyar</p>
        </div>
      </div>
      <div ref={aboutSynapseRef} className={styles.aboutSynapse}>
        <h1 className={styles.aboutText}>About Synapse</h1>
        <p className={styles.synapseDescription}>
          Synapse is a tool for students to easily create study material - be it
          flashcards, notes, mindmaps or more. We aim to provide a comprehensive
          student-focused platform which meets all of your revision needs.{" "}
        </p>
        <div className={styles.contents}>
          <div className={styles.row}>
            <div className={styles.item}>
              <img
                src={`${process.env.PUBLIC_URL}/Book Down.svg`}
                alt="Book Icon"
                className={styles.image}
              />
              Study from your flashcards and notes
            </div>
            <div className={styles.item}>
              <img
                src={`${process.env.PUBLIC_URL}/Templates.svg`}
                alt="Book Icon"
                className={styles.image}
              />
              Hop on to our active recall mode to memorise all your content
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.item}>
              <img
                src={`${process.env.PUBLIC_URL}/Bell.svg`}
                alt="Book Icon"
                className={styles.image}
              />
              Time yourself effectively with our “exam timers”
            </div>
            <div className={styles.item}>
              <img
                src={`${process.env.PUBLIC_URL}/Dashboard.svg`}
                alt="Book Icon"
                className={styles.image}
              />
              Our study mode blends together modes of learning to deliver a
              seamless study experience
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.item}>
              <img
                src={`${process.env.PUBLIC_URL}/Customers.svg`}
                alt="Book Icon"
                className={styles.image}
              />
              Connect and benefit from our user ecosystem
            </div>
            <div className={styles.item}>
              <img
                src={`${process.env.PUBLIC_URL}/Open Book.svg`}
                alt="Book Icon"
                className={styles.image}
              />
              Our smart recommendation system instructs you on what to cover
              every day to ensure retention
            </div>
          </div>
        </div>
      </div>
      <div className={styles.faqSection}>
        <h1 className={styles.faqTitle}>Frequently Asked Questions</h1>
        <FAQSlider faqs={faqs} />
      </div>
    </div>
  );
};

export default LandingPage;
