import React, { useState } from "react";
import Flashcard from "./revision/flashcards/Flashcard";
import styles from "./FAQSlider.module.css";

const FAQSlider = ({ faqs }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isQuestionFirst, setIsQuestionFirst] = useState(true);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? faqs.length - 1 : prevIndex - 1
    );
    setIsQuestionFirst(true);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === faqs.length - 1 ? 0 : prevIndex + 1
    );
    setIsQuestionFirst(true);
  };

  return (
    <div className={styles.faqSlider}>
      <button onClick={handlePrevClick} className={styles.arrowButton}>
        {"<"}
      </button>
      <Flashcard
        key={currentIndex}
        flashcard={faqs[currentIndex]}
        isQuestionFirst={isQuestionFirst}
        size={{ width: "600px", height: "400px" }}
      />
      <button onClick={handleNextClick} className={styles.arrowButton}>
        {">"}
      </button>
    </div>
  );
};

export default FAQSlider;
