import React, { useState, useEffect } from "react";
import styles from "./LoadingComponent.module.css";
import FlyingLettersComponent from "pages/CursorTrail/CursorTrail";

const quotes = [
  {
    content:
      "The only limit to our realization of tomorrow is our doubts of today.",
    author: "Franklin D. Roosevelt",
  },
  {
    content: "Life is 10% what happens to us and 90% how we react to it.",
    author: "Charles R. Swindoll",
  },
  {
    content: "The best way to predict the future is to invent it.",
    author: "Alan Kay",
  },
  {
    content:
      "Success usually comes to those who are too busy to be looking for it.",
    author: "Henry David Thoreau",
  },
  {
    content: "Believe you can and you're halfway there.",
    author: "Theodore Roosevelt",
  },
  {
    content: "Act as if what you do makes a difference. It does.",
    author: "William James",
  },
  {
    content:
      "What you get by achieving your goals is not as important as what you become by achieving your goals.",
    author: "Zig Ziglar",
  },
  {
    content: "You miss 100% of the shots you don't take.",
    author: "Wayne Gretzky",
  },
  {
    content:
      "The future belongs to those who believe in the beauty of their dreams.",
    author: "Eleanor Roosevelt",
  },
  {
    content: "It does not matter how slowly you go as long as you do not stop.",
    author: "Confucius",
  },
  {
    content:
      "Keep your face always toward the sunshine—and shadows will fall behind you.",
    author: "Walt Whitman",
  },
  {
    content:
      "Hardships often prepare ordinary people for an extraordinary destiny.",
    author: "C.S. Lewis",
  },
  {
    content: "The only way to do great work is to love what you do.",
    author: "Steve Jobs",
  },
  {
    content: "Don’t watch the clock; do what it does. Keep going.",
    author: "Sam Levenson",
  },
  {
    content:
      "The pessimist sees difficulty in every opportunity. The optimist sees opportunity in every difficulty.",
    author: "Winston Churchill",
  },
  {
    content: "Whether you think you can or think you can't, you're right.",
    author: "Henry Ford",
  },
  {
    content: "The future depends on what you do today.",
    author: "Mahatma Gandhi",
  },
  {
    content: "Start where you are. Use what you have. Do what you can.",
    author: "Arthur Ashe",
  },
  {
    content: "Dream big and dare to fail.",
    author: "Norman Vaughan",
  },
  {
    content:
      "You are never too old to set another goal or to dream a new dream.",
    author: "C.S. Lewis",
  },
  {
    content: "If you want to lift yourself up, lift up someone else.",
    author: "Booker T. Washington",
  },
  {
    content: "The purpose of our lives is to be happy.",
    author: "Dalai Lama",
  },
  {
    content: "Life is what happens when you're busy making other plans.",
    author: "John Lennon",
  },
  {
    content: "Get busy living or get busy dying.",
    author: "Stephen King",
  },
  {
    content: "You only live once, but if you do it right, once is enough.",
    author: "Mae West",
  },
  {
    content:
      "In the end, we will remember not the words of our enemies, but the silence of our friends.",
    author: "Martin Luther King Jr.",
  },
  {
    content: "The journey of a thousand miles begins with one step.",
    author: "Lao Tzu",
  },
  {
    content:
      "To succeed in life, you need two things: ignorance and confidence.",
    author: "Mark Twain",
  },
  {
    content:
      "Life isn't about finding yourself. Life is about creating yourself.",
    author: "George Bernard Shaw",
  },
  {
    content:
      "The greatest glory in living lies not in never falling, but in rising every time we fall.",
    author: "Nelson Mandela",
  },
  {
    content: "Opportunities don't happen. You create them.",
    author: "Chris Grosser",
  },
  {
    content: "The secret of success is to do the common thing uncommonly well.",
    author: "John D. Rockefeller Jr.",
  },
  {
    content:
      "If you really look closely, most overnight successes took a long time.",
    author: "Steve Jobs",
  },
  {
    content:
      "Do not go where the path may lead, go instead where there is no path and leave a trail.",
    author: "Ralph Waldo Emerson",
  },
  {
    content:
      "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    author: "Winston Churchill",
  },
  {
    content: "I find that the harder I work, the more luck I seem to have.",
    author: "Thomas Jefferson",
  },
  {
    content:
      "Success is walking from failure to failure with no loss of enthusiasm.",
    author: "Winston Churchill",
  },
  {
    content: "Don’t be afraid to give up the good to go for the great.",
    author: "John D. Rockefeller",
  },
  {
    content:
      "If you are not willing to risk the usual, you will have to settle for the ordinary.",
    author: "Jim Rohn",
  },
  {
    content: "Don’t let yesterday take up too much of today.",
    author: "Will Rogers",
  },
];

const LoadingComponent = () => {
  const [quote, setQuote] = useState(null);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setQuote(quotes[randomIndex]);
  }, []);

  if (!quote) {
    return (
      <div>
        <FlyingLettersComponent />
        <div className={styles.mainContainer}>
          <div className={styles.loadingIcon}></div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <FlyingLettersComponent />
      <div className={styles.mainContainer}>
        <div className={styles.loadingIcon}></div>
        <div className={styles.quoteSection}>
          <p className={styles.quoteText}>"{quote.content}"</p>
          <p>- {quote.author ? quote.author : "Unknown"}</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingComponent;
