import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { db, auth } from "../../config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import React from "react";

import styles from "./ShowAllTags.module.css";
import LoadingComponent from "LoadingComponent";

const ShowAllTags = () => {
  const [tags, setTags] = useState([]);

  // there's two things that have to load here - auth and data. So we will keep loading for both of them

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    // listener for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchTags();
      } else {
        // handle unauthenticated user
        navigate("/login");
      }
    });

    // cleanup function - gets rid of the listener
    return () => unsubscribe();
  }, [navigate]);

  const fetchTags = async () => {
    setIsLoading(true);
    try {
      const tagsQuery = query(
        collection(db, "tags"),
        where("owner", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(tagsQuery);
      const fetchedTags = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTags(fetchedTags);
    } catch (error) {
      console.error("Error fetching tags:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingComponent />;
  }
  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainContent}>
        <h1 className={styles.mainText}>All Tags</h1>
        {tags.length > 0 ? (
          <ul className={styles.tags}>
            {tags.map((tag) => (
              <li key={tag.id} className={styles.tag}>
                <strong className={styles.tagName}>{tag.tagName}</strong>
                <span className={styles.buttonContainer}>
                  <Link
                    to={`/tags/${tag.id}`}
                    className={`${styles.button} ${styles.view}`}
                  >
                    View
                  </Link>
                  <Link
                    to={`/tags/${tag.id}/edit`}
                    className={`${styles.button} ${styles.edit}`}
                  >
                    Edit
                  </Link>
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <div>
            <p className={styles.mainText}>No tags!</p>
          </div>
        )}
        <Link to="/tags/post" className={styles.postButton}>
          Post
        </Link>
      </div>
    </div>
  );
};

export default ShowAllTags;
